<script setup lang="ts">
// external
import { Head, Link, useForm } from "@inertiajs/vue3"

// internal
import {
  Checkbox,
  InputError,
} from "~/components"
import { RocketIllustration } from "~/illustrations"
import { Guest } from "~/layouts"

defineProps({
  canResetPassword: Boolean,
  status: String,
  emailVerificationExpired: Boolean,
  emailVerificationSuccess: Boolean,
})

const form = useForm({
  email: "",
  password: "",
  remember: false,
})

const submit = () => {
  form.post(route("login"), {
    onFinish: () => form.reset("password"),
  })
}
</script>

<template>
  <Guest>
    <template #illustration>
      <div class="hidden lg:block sm:mt-12 lg:mt-0 lg:absolute lg:-right-44 lg:-bottom-12 lg:w-[75%] pointer-events-none">
        <RocketIllustration />
      </div>
    </template>

    <Head :title="$t('auth.login.login')" />

    <div
      v-if="status"
      class="mb-4 text-sm font-medium text-green-600"
    >
      {{ status }}
    </div>

    <form
      v-cy="`login-form`"
      class="login-form"
      method="post"
      :action="route('login')"
      @submit.prevent="submit"
    >
      <div
        v-if="emailVerificationExpired"
        class="p-4 mb-4 text-sm font-medium text-yellow-600 border border-yellow-200 rounded-md bg-yellow-50"
      >
        {{ $t('auth.login.emailVerificationExpired') }}
      </div>
      <div
        v-if="emailVerificationSuccess"
        class="p-4 mb-4 text-sm font-medium text-green-600 border border-green-200 rounded-md bg-green-50"
      >
        {{ $t('auth.login.emailVerificationSuccess') }}
      </div>
      <div>
        <label
          class="block text-sm font-medium leading-6 text-gray-900"
          for="email"
        >
          {{ $t('auth.login.email') }}
        </label>
        <input
          id="email"
          v-model="form.email"
          v-cy="`input-email`"
          type="email"
          class="block w-full mt-1 input-plain"
          required
          autofocus
          autocomplete="username"
          :placeholder="$t('auth.login.emailPlaceholder') + '…'"
        >
        <InputError
          v-if="form.errors?.email"
          class="mt-2 error-container"
          :message="form.errors.email"
        />
      </div>

      <div class="mt-4">
        <label
          class="block text-sm font-medium leading-6 text-gray-900"
          for="password"
        >
          {{ $t('auth.login.password') }}
        </label>
        <input
          id="password"
          v-model="form.password"
          v-cy="`input-password`"
          type="password"
          class="block w-full mt-1 input-plain"
          required
          autocomplete="current-password"
          :placeholder="$t('auth.login.passwordPlaceholder') + '…'"
        >
        <InputError
          v-if="form.errors?.password"
          class="mt-2"
          :message="form.errors.password"
        />
      </div>

      <div class="flex justify-between mt-4">
        <label class="flex items-center">
          <Checkbox
            v-model:checked="form.remember"
            value="1"
            element-id="remember"
            name="remember"
          />
          <span class="ml-2 text-sm text-gray-600">{{ $t('auth.login.rememberMe') }}</span>
        </label>
        <Link
          v-if="canResetPassword"
          :href="route('password.request')"
          class="text-sm font-medium text-indigo-600 hover:text-indigo-700"
        >
          {{ $t('auth.login.forgotPassword') }}
        </Link>
      </div>

      <div class="block mt-6">
        <button
          v-cy="`login-submit-button`"
          class="w-full btn-primary"
          type="submit"
          :disabled="form.processing"
        >
          {{ $t('auth.login.login') }}
        </button>
      </div>
    </form>

    <p class="mt-2 text-sm text-center text-gray-500 lg:mt-4">
      <Link
        :href="route('saml.sso.create')"
        class="font-medium text-indigo-600 hover:text-indigo-700"
      >
        {{ $t('auth.login.withSso') }}
      </Link>
    </p>

    <p class="mt-6 text-sm text-center text-gray-500 lg:mt-12">
      {{ $t('auth.login.newToFynk') }}
      {{ ' ' }}
      <Link
        :href="route('register')"
        class="font-medium text-indigo-600 hover:text-indigo-700"
      >
        {{ $t('auth.login.createAccount') }}
      </Link>
    </p>
  </Guest>
</template>
